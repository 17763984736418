// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["L9m3Wux33", "I8kuIreTI", "AVucZbrfB", "ABC8aZOrs", "dPi0SWER9", "JQA_yBOIF"];

const serializationHash = "framer-By72D"

const variantClassNames = {ABC8aZOrs: "framer-v-1bcvkdp", AVucZbrfB: "framer-v-17mcm7s", dPi0SWER9: "framer-v-8q0751", I8kuIreTI: "framer-v-z3gmpv", JQA_yBOIF: "framer-v-1vzgj3d", L9m3Wux33: "framer-v-bswesd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const humanReadableVariantMap = {Giant: "L9m3Wux33", L: "AVucZbrfB", M: "ABC8aZOrs", S: "dPi0SWER9", XL: "I8kuIreTI", XS: "JQA_yBOIF"}

const getProps = ({height, iconColor, id, selectIcon, weight, width, ...props}) => { return {...props, bmzpb2BEh: selectIcon ?? props.bmzpb2BEh ?? "Heart", fBKhV5oeX: iconColor ?? props.fBKhV5oeX ?? "var(--token-d483347e-a092-4e94-abe2-4df0d72cd6e5, rgb(64, 65, 100))", h9DUG_XgS: humanReadableEnumMap[weight] ?? weight ?? props.h9DUG_XgS ?? "fill", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "L9m3Wux33"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;iconColor?: string;selectIcon?: string;weight?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fBKhV5oeX, bmzpb2BEh, h9DUG_XgS, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "L9m3Wux33", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-bswesd", className, classNames)} data-framer-name={"Giant"} layoutDependency={layoutDependency} layoutId={"L9m3Wux33"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ABC8aZOrs: {"data-framer-name": "M"}, AVucZbrfB: {"data-framer-name": "L"}, dPi0SWER9: {"data-framer-name": "S"}, I8kuIreTI: {"data-framer-name": "XL"}, JQA_yBOIF: {"data-framer-name": "XS"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-urgj6y-container"} layoutDependency={layoutDependency} layoutId={"IqID_4hWb-container"}><Phosphor color={fBKhV5oeX} height={"100%"} iconSearch={"House"} iconSelection={bmzpb2BEh} id={"IqID_4hWb"} layoutId={"IqID_4hWb"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={h9DUG_XgS} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-By72D.framer-17vn87j, .framer-By72D .framer-17vn87j { display: block; }", ".framer-By72D.framer-bswesd { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-By72D .framer-urgj6y-container { flex: none; height: 90px; position: relative; width: 90px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-By72D.framer-bswesd { gap: 0px; } .framer-By72D.framer-bswesd > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-By72D.framer-bswesd > :first-child { margin-left: 0px; } .framer-By72D.framer-bswesd > :last-child { margin-right: 0px; } }", ".framer-By72D.framer-v-z3gmpv .framer-urgj6y-container { height: 64px; width: 64px; }", ".framer-By72D.framer-v-17mcm7s .framer-urgj6y-container { height: 36px; width: 36px; }", ".framer-By72D.framer-v-1bcvkdp .framer-urgj6y-container { height: 24px; width: 24px; }", ".framer-By72D.framer-v-8q0751 .framer-urgj6y-container { height: 18px; width: 18px; }", ".framer-By72D.framer-v-1vzgj3d.framer-bswesd { height: 16px; width: 16px; }", ".framer-By72D.framer-v-1vzgj3d .framer-urgj6y-container { height: 12px; width: 12px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 90
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"I8kuIreTI":{"layout":["auto","auto"]},"AVucZbrfB":{"layout":["auto","auto"]},"ABC8aZOrs":{"layout":["auto","auto"]},"dPi0SWER9":{"layout":["auto","auto"]},"JQA_yBOIF":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"fBKhV5oeX":"iconColor","bmzpb2BEh":"selectIcon","h9DUG_XgS":"weight"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerzStooEXwt: React.ComponentType<Props> = withCSS(Component, css, "framer-By72D") as typeof Component;
export default FramerzStooEXwt;

FramerzStooEXwt.displayName = "Icons/Pictogram";

FramerzStooEXwt.defaultProps = {height: 90, width: 90};

addPropertyControls(FramerzStooEXwt, {variant: {options: ["L9m3Wux33", "I8kuIreTI", "AVucZbrfB", "ABC8aZOrs", "dPi0SWER9", "JQA_yBOIF"], optionTitles: ["Giant", "XL", "L", "M", "S", "XS"], title: "Variant", type: ControlType.Enum}, fBKhV5oeX: {defaultValue: "var(--token-d483347e-a092-4e94-abe2-4df0d72cd6e5, rgb(64, 65, 100)) /* {\"name\":\"Neutral/700\"} */", title: "Icon Color", type: ControlType.Color}, bmzpb2BEh: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Heart", description: undefined, hidden: undefined, title: "Select icon"}, h9DUG_XgS: PhosphorControls?.["weight"] && {...PhosphorControls["weight"], defaultValue: "fill", description: undefined, hidden: undefined, title: "Weight"}} as any)

addFonts(FramerzStooEXwt, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})